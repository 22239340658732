import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import styled, { css } from 'styled-components'

type AnswersMaxsLinesProps = {
  $height: number
  $isClamped: boolean
}

type PictoProps = {
  $isExpanded: boolean
}

export const Bloc = styled.section`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.neutral4};
  overflow: hidden;
  padding: ${g(6)} 0 ${g(8)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} 0 ${g(11)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(11)} 0 ${g(16)};
  }
`

export const TitleBloc = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    margin-bottom: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 4])};
    margin-bottom: 0px;
  }
`

export const Surtitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(1)};
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading4};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

export const QuestionsBloc = styled.ul`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([4, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
    margin-top: ${g(4)};
  }
`

export const Couple = styled.li`
  display: flex;
  flex-direction: column;
  padding-left: ${g(3)};
  border-left: 1px solid ${({ theme }) => theme.colors.neutral2};

  & + & {
    margin-top: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-left: ${g(5)};

    & + & {
      margin-top: ${g(6)};
    }
  }
`

export const Question = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(1)};
`

export const Answer = styled.div`
  position: relative;
  ${({ theme }) => theme.typos.body4};
`

export const Content = styled(RichContent).attrs({ allowedTypes: ['a', 'strong'] })<AnswersMaxsLinesProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  transition: height 400ms cubic-bezier(0.25, 1, 0.5, 1);

  ${({ $height }) => css`
    height: ${$height}px;
  `};

  ${({ $isClamped }) =>
    $isClamped &&
    css`
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    `};
`

const HiddenContent = styled(RichContent).attrs({ allowedTypes: ['a', 'strong'] })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
`

export const HiddenContentMax = styled(HiddenContent)``

export const HiddenContentMin = styled(HiddenContent)`
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

export const ViewMore = styled.button`
  display: flex;
  align-items: center;
  margin-top: ${g(1)};
  cursor: pointer;
`

export const Text = styled.div`
  ${({ theme }) => theme.typos.body4Underline};
`
export const Picto = styled.div<PictoProps>`
  height: ${g(2)};
  width: ${g(2)};
  margin-left: ${g(1)};
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.6s ease-in-out;
`

import { useResize } from '@pretto/zen/reveal/lib/useResize'

import { ChevronDownBold } from '@pretto/picto'

import { useMemo, useRef, useState } from 'react'

import * as S from './RowFaq.styles'

export type RowFaqProps = {
  answersMaxLines?: number
  isColored?: boolean
  questions: { question: string; answer: string }[]
  suptitle?: string
  title: string
}

export const RowFaq: React.FC<RowFaqProps> = ({ answersMaxLines, questions, suptitle, title }) => {
  const [isClamped, setIsClamped] = useState<boolean[]>(questions.map(() => true))
  const [isExpanded, setIsExpanded] = useState<boolean[]>(questions.map(() => false))

  const hiddenContentMaxRef = useRef<HTMLDivElement[]>([])
  const hiddenContentMinRef = useRef<HTMLDivElement[]>([])

  const size = useResize()

  const maxHeights = useMemo(() => {
    return hiddenContentMaxRef.current.map(element => element.getBoundingClientRect().height)
  }, [hiddenContentMaxRef.current, size])

  const minHeights = useMemo(() => {
    return hiddenContentMinRef.current.map(element => element.getBoundingClientRect().height)
  }, [hiddenContentMinRef.current, size])

  return (
    <S.Bloc itemScope itemType="https://schema.org/FAQPage">
      <S.TitleBloc>
        {suptitle && <S.Surtitle>{suptitle}</S.Surtitle>}
        <S.Title>{title}</S.Title>
      </S.TitleBloc>

      <S.QuestionsBloc>
        {questions.map(({ question, answer }, index) => {
          const handleOnViewMoreClick = () => {
            setIsExpanded(prev => prev.map((value, i) => (i === index ? !value : value)))
          }

          const handleHiddenContentMaxRef = (node: HTMLDivElement) => {
            hiddenContentMaxRef.current[index] = node
          }

          const handleHiddenContentMinRef = (node: HTMLDivElement) => {
            hiddenContentMinRef.current[index] = node
          }

          const handleTransitionEnd = () => {
            setIsClamped(prev => prev.map((value, i) => (i === index ? !isExpanded[i] : value)))
          }

          const handleTransitionStart = () => {
            setIsClamped(prev => prev.map((value, i) => (i === index ? false : value)))
          }

          return (
            <S.Couple itemScope itemProp="mainEntity" itemType="https://schema.org/Question" key={question}>
              <S.Question itemProp="name">{question}</S.Question>

              <S.Answer itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                <S.Content
                  $height={isExpanded[index] ? maxHeights[index] : minHeights[index]}
                  $isClamped={isClamped[index]}
                  onTransitionEnd={handleTransitionEnd}
                  onTransitionStart={handleTransitionStart}
                  itemProp="text"
                >
                  {answer}
                </S.Content>

                <S.HiddenContentMax ref={handleHiddenContentMaxRef}>{answer}</S.HiddenContentMax>
                <S.HiddenContentMin ref={handleHiddenContentMinRef}>{answer}</S.HiddenContentMin>
              </S.Answer>

              {answersMaxLines && (
                <S.ViewMore onClick={handleOnViewMoreClick}>
                  <S.Text>{isExpanded[index] ? 'Voir moins' : 'Voir plus'}</S.Text>
                  <S.Picto as={ChevronDownBold} $isExpanded={isExpanded[index]}></S.Picto>
                </S.ViewMore>
              )}
            </S.Couple>
          )
        })}
      </S.QuestionsBloc>
    </S.Bloc>
  )
}
